/* eslint-disable new-cap */
import React from 'react';
import '../style.scss';
import {
  BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
// import Counter from '../containers/counter';
// import Controls from '../containers/controls';
import Welcome from '../containers/welcome';
import About from '../containers/about';
import Services from '../containers/services';
import Signin from '../containers/signin';
import Signup from '../containers/signup';
import Nav from '../containers/navBar';
import PrivateRoute from '../containers/privateRoute';
import YPP from '../containers/serviceInfo/ypp';

const Test = (props) => {
  return <div> ID: {props.match.params.id} </div>;
};

const FallBack = (props) => {
  return <div>URL Not Found</div>;
};

const App = (props) => {
  return (
    <Router>
      <span>
        <Nav />
        <div id="display">
          <Switch>
            <Route exact path="/" component={Welcome} />
            <Route path="/about" component={About} />
            <Route path="/services" component={Services} />
            <Route path="/signin" component={Signin} />
            <Route path="/signup" component={Signup} />
            <PrivateRoute exact path="/test/:id" component={Test} />
            <Route path="/ypp" component={YPP} />
            <Route component={FallBack} />
          </Switch>
        </div>
      </span>
    </Router>
  );
};

export default App;
