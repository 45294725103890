import React from 'react';

const YPP = (props) => {
  return (
    <div id="websiteInfoPage">
      <div id="ypp">
        Young Pathfinders Program
      </div>
      <div id="websiteInfoSection">
        <h1>
          Introduction
        </h1>
        <p>
          The Young Pathfinders Program is geared towards helping middle school to early high school-aged students understand the different industries and jobs out in the world.
          It is broken up into 2 years of programming, with the first year focused on exploration, and the 2nd year focused on execution.
        </p>
        <h1>
          1st Year: Exploration
        </h1>
        <p>
          The first year programming is composed of 3 component pieces:
          <ul>
            <li>Industry Exposure Lecture Series</li>
            <li>Soft Skills Book Club/Discussion Groups</li>
            <li>Volunteer Opportunities</li>
          </ul>
          The Industry Exposure Lecture Series is a monthly lecture series from January to September that focuses on exploring the pros and cons of different industries.
          Furthermore, it exposes students to the typical pathways towards entering these fields.
          <br /><br />
          The Soft Skills Book Club/Discussion Groups is a monthly discussion group that centers aroudn soft skill classics
          and case studies to give students an introduction to applying soft skills in group settings.
          <br /><br />
          The Volunteer Opportunities are first come first serve opportunities for students to gain some hands on experience in just doing non-school work,
          ranging from coordinating events to building excel based inventory systems.
          These come pretty sporadically throughout the year, and are meant to give students the opportunity to practice their on-the-job learning and thinking abilities.
          <br /><br />
          After going through the first year programming, students should be able to understand which industries they are interested in, and have a general academic and career direction picked out.
          <br /><br />
          The following is the calendar for the 2020-2021 1st Year YPP.
          <br />
          {/* <iframe id="pdfDoc" title="YPP First Year Calendar 2020-2021" src="src/assets/YPPCalendar2021.pdf" /> */}
          <div id="yppPDFDoc" />
        </p>
        <h1>
          2nd Year: Execution
        </h1>
        <p>
          The second year programming is intended for students to test whether their chosen academic and career directions do fit their passions.
          The program splits the 2nd year cohort into smaller groups based on similar industry interests, and assigns a mentor to each group.
          These groups then attempt to tackle sandbox projects within these industries to get a taste of how work within the industry will look.
          <br /><br />
          The intent is for students to figure out whether this work is something they actually see themselves doing in the future long term.
          <br /><br />
          Given that the YPP program had its first cohort class in 2020, there is currently no second year programming just yet, but will be established starting in 2021-2022.
          Future projects will be posted here.
        </p>
      </div>
    </div>
  );
};

export default YPP;
