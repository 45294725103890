import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';

const Services = (props) => {
  return (
    <span>
      <div id="services">
        <span id="servicesTitle">
          Our Services
        </span>
        <span id="servicesIntroduction">
          Our flagship service is our mentorship program that spans from high school through college.
          Our driving philosophy in this program is that we don&apos;t just see college as the end-all be-all, but rather a stepping stone towards a more fulfilling life and career.
          There, the program aims to help young adults understand the pros and cons of different industries and career paths, and help them decide where their passions lie given this information.
          Furthermore, the program also provides more traditional services like academic and internship application support, and extracurricular planning.
          <br /><br />
          In addition to our flagship mentorship program, we also have other specialized career and personal development programs,
          including the Ivy+ program for high performing students who have Ivy League+ aspirations,
          industry-specific programs for students who are pretty confident in their career interests,
          and the young pathfinders program for middle to early high school students who want a stress free way to gain understanding of what different career paths typically look like.
        </span>
      </div>

      <div id="serviceList">
        <ul>

          <li>
            <div id="serviceNameIcon">
              <div id="mentorshipProgramIcon" />
              <br />
              Mentorship Program
            </div>
            <div id="serviceItemDescription">
              Geared at helping high school and college-aged students discover their interests and ultimately begin a career in their chosen field. {/* Read More */}
            </div>
          </li>

          <li>
            <div id="serviceNameIcon">
              <div id="ivyPlusIcon" />
              <br />
              Ivy+ (Generalist)
            </div>
            <div id="serviceItemDescription">
              Geared at guiding high-performing students with Ivy League+ aspirations. This is a more generalist program compared to our other services.
            </div>
          </li>

          <li>
            <div id="serviceNameIcon">
              <div id="medicalIcon" />
              <br />
              Industry-Spec: Medical
            </div>
            <div id="serviceItemDescription">
              Industry-specific program for high school and college-aged students who are confident they are passionate about the medical field.
            </div>
          </li>

          <li>
            <div id="serviceNameIcon">
              <div id="quantFinanceIcon" />
              <br />
              Industry-Spec: Quant Finance
            </div>
            <div id="serviceItemDescription">
              Industry-specific program for high school and college-aged students who are confident they are passionate about the quant finance field.
            </div>
          </li>

          <li>
            <div id="serviceNameIcon">
              <div id="softwareEngineeringIcon" />
              <br />
              Industry-Spec: Software Engineering
            </div>
            <div id="serviceItemDescription">
              Industry-specific program for high school and college-aged students who are confident they are passionate about the software engineering field.
            </div>
          </li>

          <li>
            <div id="serviceNameIcon">
              <div id="dataScienceIcon" />
              <br />
              Industry-Spec: Data Science
            </div>
            <div id="serviceItemDescription">
              Industry-specific program for high school and college-aged students who are confident they are passionate about the data science field.
            </div>
          </li>

          <li>
            <div id="serviceNameIcon">
              <div id="educationIcon" />
              <br />
              Industry-Spec: Education
            </div>
            <div id="serviceItemDescription">
              Industry-specific program for high school and college-aged students who are confident they are passionate about the education field.
            </div>
          </li>

          <li>
            <div id="serviceNameIcon">
              <div id="yppIcon" />
              <br />
              Young Pathfinders Program
            </div>
            <div id="serviceItemDescription">
              Geared towards helping middle school to early high school-aged students understand the different industries and jobs out in the world.
              <NavLink to="/ypp" id="serviceLink"> More Info</NavLink>
            </div>
          </li>

        </ul>
      </div>
    </span>
  );
};

const mapStateToProps = (state) => (
  {
    auth: state.auth.authenticated,
  }
);

export default withRouter(connect(mapStateToProps, null)(Services));
