// keys for actiontypes
import axios from 'axios';

export const ActionTypes = {
  FETCH_STUDENTS: 'FETCH_STUDENTS',
  FETCH_STUDENT: 'FETCH_STUDENT',
  UPDATE_STUDENT: 'UPDATE_STUDENT',
  CREATE_STUDENT: 'CREATE_STUDENT',
  DELETE_STUDENT: 'DELETE_STUDENT',
  AUTH_USER: 'AUTH_USER',
  DEAUTH_USER: 'DEAUTH_USER',
  AUTH_ERROR: 'AUTH_ERROR',
};

// const ROOT_URL = 'http://localhost:9090/api';
const ROOT_URL = 'https://ccgbackend.herokuapp.com/api';
const API_KEY = '';

export function fetchStudents() {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/posts${API_KEY}`).then((response) => {
      // do something with response.data  (some json)
      dispatch({ type: ActionTypes.FETCH_STUDENTS, payload: response.data });
    }).catch((error) => {
      // hit an error do something else!
      console.log('Oh no!! Something went wrong when you tried to fetch all students.');
    });
  };
}

export function fetchStudent(id) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/posts/${id}${API_KEY}`).then((response) => {
      // do something with response.data  (some json)
      dispatch({ type: ActionTypes.FETCH_STUDENT, payload: response.data });
      console.log(response.data);
    }).catch((error) => {
      // hit an error do something else!
      console.log(`Oh no!! Something went wrong when you tried to fetch student ${id}.`);
    });
  };
}

export function createStudent(student, history) {
  return (dispatch) => {
    axios.post(`${ROOT_URL}/posts${API_KEY}`, student, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      // do something with response.data  (some json)
      dispatch({ type: ActionTypes.CREATE_STUDENT, payload: response.data });
      history.push('/');
    }).catch((error) => {
      // hit an error do something else!
      console.log('Oh no!! Failed to create new student.');
    });
  };
}

export function deleteStudent(id, history) {
  return (dispatch) => {
    axios.delete(`${ROOT_URL}/posts/${id}${API_KEY}`, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      // do something with response.data  (some json)
      dispatch({ type: ActionTypes.DELETE_STUDENT, payload: response.data });
      history.push('/');
    }).catch((error) => {
      // hit an error do something else!
      console.log(`Oh no!! Failed to delete student ${id}.`);
    });
  };
}

export function updateStudent(id, post) {
  return (dispatch) => {
    axios.put(`${ROOT_URL}/posts/${id}${API_KEY}`, post, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      // do something with response.data  (some json)
      dispatch({ type: ActionTypes.UPDATE_STUDENT, payload: response.data });
    }).catch((error) => {
      // hit an error do something else!
      console.log(`Oh no!! Failed to update student ${id}.`);
    });
  };
}

// trigger to deauth if there is error
// can also use in your error reducer if you have one to display an error message
export function authError(error) {
  console.log(error);
  return {
    type: ActionTypes.AUTH_ERROR,
    message: error,
  };
}

export function signinUser({ email, password }, history) {
  // takes in an object with email and password (minimal user object)
  // returns a thunk method that takes dispatch as an argument (just like our create post method really)
  // does an axios.post on the /signin endpoint
  // on success does:
  //  dispatch({ type: ActionTypes.AUTH_USER });
  //  localStorage.setItem('token', response.data.token);
  // on error should dispatch(authError(`Sign In Failed: ${error.response.data}`));
  return (dispatch) => {
    axios.post(`${ROOT_URL}/signin`, { email, password }).then((response) => {
      // do something with response.data  (some json)
      dispatch({ type: ActionTypes.AUTH_USER });
      localStorage.setItem('token', response.data.token);
      history.push('/');
    }).catch((error) => {
      // hit an error do something else!
      dispatch(authError(`Sign In Failed: ${error.response.data}`));
    });
  };
}

export function signupUser({ email, username, password }, history) {
  // takes in an object with email and password (minimal user object)
  // returns a thunk method that takes dispatch as an argument (just like our create post method really)
  // does an axios.post on the /signup endpoint (only difference from above)
  // on success does:
  //  dispatch({ type: ActionTypes.AUTH_USER });
  //  localStorage.setItem('token', response.data.token);
  // on error should dispatch(authError(`Sign Up Failed: ${error.response.data}`));
  return (dispatch) => {
    axios.post(`${ROOT_URL}/signup`, { email, username, password }).then((response) => {
    // do something with response.data  (some json)
      dispatch({ type: ActionTypes.AUTH_USER });
      localStorage.setItem('token', response.data.token);
      history.push('/');
    }).catch((error) => {
    // hit an error do something else!
      dispatch(authError(`Sign Up Failed: ${error.response.data}`));
    });
  };
}

// deletes token from localstorage
// and deauths
export function signoutUser(history) {
  return (dispatch) => {
    localStorage.removeItem('token');
    dispatch({ type: ActionTypes.DEAUTH_USER });
    history.push('/');
  };
}
