import React from 'react';

const Welcome = (props) => {
  return (
    <div id="welcome">
      <span id="welcomeCompanyName">
        Cambridge Consultation Group
      </span>
      <span id="tagline">
        Academic and Career Mentorship at Your Fingertips
      </span>
    </div>
  );
};

export default Welcome;
