import React from 'react';

const About = (props) => {
  return (
    <span id="about">
      {/* Mission Statement */}
      <div id="missionStatement">
        <div id="missionStatementTitle">
          Mission Statement
        </div>
        <div id="missionStatementText">
          Cambridge Consultation is a team of counselors, consultants, and support staff geared to help you establish and achieve academic and career goals.
          We hope to use our expertise and experience to help you develop a fulfilling career path through higher education, profession education, and/or professional training.
          We strongly believe that by finding a purpose, life is greatly enriched; therefore, we make it our goal to help you find your calling.
        </div>
      </div>

      {/* Meet The Team */}
      <div id="meetTheTeam">
        <div id="meetTheTeamTitle">
          Meet The Management Team
        </div>
        <div id="meetTheTeamIntroduction">
          The Cambridge Group becomes part of your team - putting limitless capabilities and expertise at your disposal.
          Meet the Cambridge Management Team members, who have been carefully chosen and vetted for their experience, reputation, track record, and overall client satisfaction.
        </div>

        <div id="meetTheTeamContent">

          {/* Dr. James Lin */}
          <div className="teamMember">
            <div className="teamMemberSummary">
              <div id="drJamesLinHeadshot" />
              <div className="teamMemberName">Dr. James Lin</div>
              <div className="teamMemberAlmaMater">(UC Berkeley)</div>
              <div className="teamMemberTitle">Principle Consultant</div>
            </div>
            <div className="teamMemberIntro">
              Dr. James Lin has been with the Cambridge Group for over 15 years, and has more than 15 years of experience helping high school students apply for both public and private college,
              and 10 years experience helping college students apply for medical school.
              He specializes in applications for the BS MD programs, and is especially interested in helping students navigate the pre-medical track.
              In addition to his highly successful college counseling services, he is involved with the USA Biology Olympiad Team,
              and consistently trains 2-3 of the top 20 USA biology students every year.
              In recent years, his students have been accepted to every single Ivy League, every UC school, USC, Stanford, and Johns Hopkins.
            </div>
          </div>

          {/* Dr. Janet Jiang PhD */}
          <div className="teamMember">
            <div className="teamMemberSummary">
              <div id="drJanetJiangHeadshot" />
              <div className="teamMemberName">Dr. Janet Jiang PhD</div>
              <div className="teamMemberAlmaMater">(Shanghai Jiaotong U.)</div>
              <div className="teamMemberTitle">Managing Consultant</div>
            </div>
            <div className="teamMemberIntro">
              Dr. Janet Jiang is the founder of the Cambridge Group and has more than 20 years of experience helping high school students apply for both public nad private colleges.
              She specializes in managing student-parent relationships through the teenage years, and is involved heavily in the local education jurisdiction in the LA area.
              She holds a PhD in biomedical engineering, as well as teaching credentials for advanced mathematics.
              In recent years, her students have been accepted to Harvard, Columbia, UPenn, Dartmouth, Brown, Cornell, every UC school, Stanford,
              USC, Johns Hopkins, UIUC, UT Austin, UChicago, MIT, Cal Tech, and Northwestern.
            </div>
          </div>

          {/* Mr. RJ Yang */}
          <div className="teamMember">
            <div className="teamMemberSummary">
              {/* <img className="teamMemberHeadshot" src="img/RJYangHeadshot.jpg" alt="Mr. RJ Yang Headshot" /> */}
              <div id="rjyangHeadshot" />
              <div className="teamMemberName">Mr. RJ Yang</div>
              <div className="teamMemberAlmaMater">(Dartmouth College)</div>
              <div className="teamMemberTitle">Managing Consultant</div>
            </div>
            <div className="teamMemberIntro">
              Mr. RJ Yang has been working with the Cambridge Group for more than 10 years,
              and has 9 years of experience working with students applying to private high schools, colleges, academic positions, and internships.
              After graduating valedictorian from Troy High School, RJ went on to study at Dartmouth College,
              where he graduated Magna Cum Laude with high honors in both Computer Engineering and Economics.
              He then worked as a hedge fund quant before finding his way into education.
              He specializes in helping students enter computational fields, and is passionate about helping them figure out what they are interested in.
              In recent years, his students have been accepted to Harvard, Yale, UPenn, Brown, Cornell, USC, all of the UCs, UT Austin, UIUC, UMTC, Northwestern, and Northeastern.
            </div>
          </div>

          {/* Other Team Members go here */}
        </div>
      </div>

      {/* Track Record Logo Cloud */}

      <div id="trackRecordHeader">
        <div id="trackRecordTitle">
          A Proven Track Record
        </div>
        <div id="trackRecordTagline">
          Our Students Have Been Accepted To:
        </div>
      </div>
      <div id="trackRecord">
        <div id="trackRecordCloud" />
      </div>
      <div id="trackRecordFooter">
        <div id="trackRecordDisclaimer">
          Only a Partial List Due to Space Considerations
          <br />
          Showing Results from 2015 through 2020
          <br />
          Copyright 2020 Cambridge Consultation Group
        </div>
      </div>

      {/* Track Record Details */}

      {/* Cambridge By The Numbers */}

    </span>
  );
};

export default About;
