import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { signoutUser } from '../actions/index';

class Nav extends Component {
  constructor(props) {
    super(props);

    this.onSignoutSubmit = this.onSignoutSubmit.bind(this);
    this.renderLoginItems = this.renderLoginItems.bind(this);
  }

  onSignoutSubmit() {
    this.props.signoutUser(this.props.history);
  }

  renderLoginItems() {
    if (this.props.auth) {
      return (
        <nav>
          <ul className="header">
            <li>
              <ul id="logoAndName">
                <li id="logo" />
                <li id="companyName">CCG</li>
              </ul>
            </li>
            <li>
              <ul id="navMenu">
                <li><NavLink to="/" exact id="navMenuItem">Home</NavLink></li>
                <li><NavLink to="/about" id="navMenuItem">About</NavLink></li>
                <li><NavLink to="/services" id="navMenuItem">Services</NavLink></li>
                <li>
                  <NavLink id="navMenuSignout" exact to="/">
                    <span onClick={this.onSignoutSubmit} role="button" tabIndex={0} id="signoutSubmit">Signout</span>
                  </NavLink>
                </li>
                {/* <li><NavLink to="/test/id1" id="navMenuItem">Log In</NavLink></li> */}
                {/* <li><NavLink to="/test/id2" id="navMenuItem">test id2</NavLink></li> */}
              </ul>
            </li>
          </ul>
        </nav>
      );
    } else {
      return (
        <nav>
          <ul className="header">
            <li>
              <ul id="logoAndName">
                <li id="logo" />
                <li id="companyName">CCG</li>
              </ul>
            </li>
            <li>
              <ul id="navMenu">
                <li><NavLink to="/" exact id="navMenuItem">Home</NavLink></li>
                <li><NavLink to="/about" id="navMenuItem">About</NavLink></li>
                <li><NavLink to="/services" id="navMenuItem">Services</NavLink></li>
                <li><NavLink id="navMenuItem" to="/signin" exact>Signin</NavLink></li>
                {/* <li><NavLink id="navMenuItem" to="/signup">Signup</NavLink></li> */}
                {/* <li><NavLink to="/test/id1" id="navMenuItem">Log In</NavLink></li> */}
                {/* <li><NavLink to="/test/id2" id="navMenuItem">test id2</NavLink></li> */}
              </ul>
            </li>
          </ul>
        </nav>
      );
    }
  }

  render() {
    return (
      <nav>
        {this.renderLoginItems()}
      </nav>
    );
  }
}

const mapStateToProps = (state) => (
  {
    auth: state.auth.authenticated,
  }
);

export default withRouter(connect(mapStateToProps, { signoutUser })(Nav));
