import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signinUser } from '../actions/index';

// this can be dumb or smart component - connect works with either
class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onSigninSubmit = this.onSigninSubmit.bind(this);
  }

  //   componentDidMount() {
  //   }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onPasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  onSigninSubmit() {
    const authInfo = {
      email: this.state.email,
      password: this.state.password,
    };
    console.log(authInfo);
    this.props.signinUser(authInfo, this.props.history);
  }

  render() {
    return (
      <div id="signInUser">
        <div id="signInUserBoundaries">
          <h1>Sign In:</h1>
          <p>Email:</p>
          <input className="newPostInput" onChange={this.onEmailChange} value={this.state.email} placeholder="Email" />
          <p>Password:</p>
          <input className="newPostInput" onChange={this.onPasswordChange} value={this.state.password} placeholder="Password" type="password" />
          <br />
          <button type="button" onClick={this.onSigninSubmit} id="newPostButton">Submit</button>
        </div>
      </div>
    );
  }
}

// connects particular parts of redux state to this components props
const mapStateToProps = (state) => (
  {
    count: state.count,
  }
);

// react-redux glue -- outputs Container that know state in props
// also with an optional HOC withRouter
export default withRouter(connect(mapStateToProps, { signinUser })(Signin));
