import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signupUser } from '../actions/index';

// this can be dumb or smart component - connect works with either
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      username: '',
      password: '',
    };
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onSignupSubmit = this.onSignupSubmit.bind(this);
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onUsernameChange(event) {
    this.setState({ username: event.target.value });
  }

  onPasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  onSignupSubmit() {
    const authInfo = {
      email: this.state.email,
      username: this.state.username,
      password: this.state.password,
    };
    this.props.signupUser(authInfo, this.props.history);
  }

  render() {
    return (
      <div>
        <h1>Signup:</h1>
        <p>Email:</p>
        <input className="newPostInput" onChange={this.onEmailChange} value={this.state.email} placeholder="Email" />
        <p>Username:</p>
        <input className="newPostInput" onChange={this.onUsernameChange} value={this.state.username} placeholder="Username" />
        <p>Password:</p>
        <input className="newPostInput" onChange={this.onPasswordChange} value={this.state.password} placeholder="Password" type="password" />
        <br />
        <button type="button" onClick={this.onSignupSubmit} id="newPostButton">Submit</button>
      </div>
    );
  }
}

// connects particular parts of redux state to this components props
const mapStateToProps = (state) => (
  {
    count: state.count,
  }
);

// react-redux glue -- outputs Container that know state in props
// also with an optional HOC withRouter
export default withRouter(connect(mapStateToProps, { signupUser })(Signup));
